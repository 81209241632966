@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  margin-top: 50px;
}

.slots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.linha {
  display: flex;
}

.linha span {
  font-size: 36px;
  padding: 10px;
}

@keyframes pulsateOpacity {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.pulsate {
  animation: pulsateOpacity 1s infinite;
  font-weight: bold;
  color: #fff;
}

@keyframes pulsateText {
  0% { text-shadow: 0 0 20px rgba(255, 0, 0, 0.1); }
  50% { text-shadow: 0 0 20px rgba(255, 0, 0, 0.8); }
  100% { text-shadow: 0 0 20px rgba(255, 0, 0, 0.1); }
}

.pulsate-text {
  animation: pulsateText 1s infinite;
  font-weight: bold; /* Opcional, para dar mais destaque */
  color: #fff; /* Ajuste a cor do texto, se necessário */
}
